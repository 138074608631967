import { Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { ErrorComponent, RouteComponent } from '@cmi/shared/components';
import {
  USER_PERMISSION_ADMIN,
  USER_PERMISSION_CLIENT,
  USER_PERMISSION_INTERNAL
} from '@cmi/store/user';
import { MsalConfigGuard, MsalLoginGuard, PermissionGuard } from 'wre-authlib';

export const routes: Routes = [
  {
    path: 'login',
    component: MsalRedirectComponent,
    canActivate: [MsalLoginGuard]
  },
  // TODO: uncomment below to access design page
  // {
  //   path: 'design', // TEMPORARY: for UX design
  //   loadChildren: () => import('./design/design.routes').then((m) => m.DESIGN_ROUTES)
  // },
  {
    path: '',
    loadChildren: () => import('./sign-in/sign-in.routes').then((m) => m.SIGN_IN_ROUTES)
  },
  {
    path: '',
    canActivate: [MsalConfigGuard],
    children: [
      {
        path: '',
        canActivate: [MsalGuard],
        children: [
          {
            path: 'pdf-viewer',
            canActivate: [PermissionGuard],
            data: {
              permissions: [
                USER_PERMISSION_ADMIN,
                USER_PERMISSION_INTERNAL,
                USER_PERMISSION_CLIENT
              ],
              redirectUrl: 'unauthorized'
            },
            loadChildren: () =>
              import('./pdf-viewer/pdf-viewer.routes').then((m) => m.PDF_VIEWER_ROUTES)
          }
        ]
      },
      {
        path: '',
        component: RouteComponent,
        canActivate: [MsalGuard],
        children: [
          {
            path: 'unauthorized',
            component: ErrorComponent,
            data: {
              errorCode: 403
            }
          },
          {
            path: 'admin',
            canActivate: [PermissionGuard],
            loadChildren: () => import('./admin/admin.routes').then((m) => m.ADMIN_ROUTES),
            data: {
              permissions: [USER_PERMISSION_ADMIN],
              redirectUrl: 'unauthorized'
            }
          },
          {
            path: 'dashboard',
            canActivate: [PermissionGuard],
            loadChildren: () =>
              import('./dashboard/dashboard.routes').then((m) => m.DASHBOARD_ROUTES),
            data: {
              permissions: [
                USER_PERMISSION_ADMIN,
                USER_PERMISSION_INTERNAL,
                USER_PERMISSION_CLIENT
              ],
              redirectUrl: 'unauthorized'
            }
          },
          { path: '**', pathMatch: 'full', component: ErrorComponent }
        ]
      }
    ]
  }
];
